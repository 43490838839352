<template>
  <div>
    <div class="card-body p-0">
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        loading-text="Malumot yuklanmoqda..."
        :items="getCurrContractDetails"
        hide-default-footer
      >
        <template v-slot:[`item.action`]="{ item }" class="text-right">
          <div v-if="item !== undefined">
            <a @click="getExcel(item.id)">
              <span
                class="
                  label
                  link
                  label-lg label-light-success label-inline
                  mx-1
                "
              >
                Export to Excel
              </span>
            </a>
            <router-link :to="'/appendetails/' + item.id" class="mx-1">
              <span
                class="label label-lg label-light-primary label-inline mx-1"
              >
                {{ $t('ACTION.DETAILS') }}
              </span>
            </router-link>
            <router-link
              v-if="item.editable"
              :to="'/contractdetails/appendix/' + id"
              class="mx-1"
            >
              <span class="label label-lg label-light-warning label-inline">
                {{ $t('ACTION.UPDATE') }}
              </span>
            </router-link>
            <a v-if="!item.has_zayavka" @click="deleteItem(item.id)">
              <span
                class="label link label-lg label-light-danger label-inline mx-1"
              >
                O'chirish
              </span>
            </a>
          </div>
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | formatDate }}
        </template>
      </v-data-table>
    </div>
    <div>
      <b-modal class="table-modal" centered id="table-modal" hide-footer>
        <div class="test">
          <table class="zebra">
            <thead>
              <tr>
                <td class="table__cell" rowspan="2">Manzil</td>
                <td
                  class="table__cell"
                  colspan="3"
                  v-for="truck in gettruck"
                  :key="truck.id"
                >
                  {{
                    truckModel(truck, selectedRow).trucktype_description.brand
                      .name
                  }}
                  {{
                    truckModel(truck, selectedRow).trucktype_description.model
                  }}
                  <div>
                    (yuk ogirligi:
                    {{
                      truckModel(truck, selectedRow).trucktype_description
                        .capacity_kg
                    }}
                    kg <br />
                    yuk sig'imi:
                    {{
                      truckModel(truck, selectedRow).trucktype_description
                        .capacity_m3
                    }}
                    m3)
                    <span
                      class="label label-lg label-light-warning label-inline"
                      v-if="truckModel(truck, selectedRow).is_ref"
                    >
                      Ref
                    </span>
                  </div>
                </td>
                <!-- <td></td> -->
              </tr>
              <tr>
                <td
                  class="table__cell"
                  v-for="i in gettruck.length * 3"
                  :key="i"
                >
                  <strong>
                    {{ i | headTable }}
                  </strong>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(detail, indexMain) in selectedRow.marshrut_description"
                :key="indexMain"
              >
                <td
                  class="table__cell"
                  style="border-left: 1px solid #000; position: sticky; left: 0"
                >
                  <dir style="display: flex">
                    <span
                      style="display: flex"
                      v-for="(detaill, index) in detail.cities"
                      :key="detaill.id"
                    >
                      {{ detaill.city_name.name }}
                      <span v-if="index !== detail.cities.length - 1">
                        <span>—</span>
                      </span>
                    </span>
                  </dir>
                </td>
                <td
                  class="table__cell"
                  v-for="(truck, index) in gettruck.length * 3"
                  :key="index"
                >
                  {{ index | truckPrice(detail.prices, gettruck) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import axios from 'axios'

export default {
  props: ['id'],
  data() {
    return {
      selectedItem: '',
      dialog: false,
      headers: [
        {
          text: 'Id',
          value: 'id'
        },
        {
          text: 'Nomi',
          value: 'name'
        },
        {
          text: 'Yaratilgan sana',
          value: 'created_at'
        },
        {
          value: 'action',
          text: '',
          align: 'end',
          sortable: false
        }
      ]
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllTruckTypes')
    this.$store.dispatch('getNds')
  },
  filters: {
    formatDate: function (val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    },
    // truckModel(set, trucks) {
    //   let data
    //   if (Boolean(trucks) && Boolean(data.marshrut_description))
    //     data.marshrut_description.forEach(truck => {
    //       data = truck.prices.find(
    //         x => x.truck_type == set.truck_type && x.is_ref == set.is_ref
    //       )
    //     })
    //   return data
    // },
    headTable(index) {
      if (index % 3 == 1) {
        return 'Summa'
      } else if (index % 3 == 2) {
        return 'Nds '
      } else if (index % 3 == 0) {
        return 'Summa(nds)'
      }
    },
    truckPrice(index, list, truckType) {
      var i = Math.floor(index / 3)
      let data = ''
      data = list.find(
        (x) =>
          x.truck_type == truckType[i].truck_type &&
          x.is_ref == truckType[i].is_ref
      )
      if (data) {
        if (index % 3 == 0) {
          data = data.price.toLocaleString('zh-ZH')
        }
        if (index % 3 == 1) {
          data = data.nds_amout.toLocaleString('zh-ZH')
        }
        if (index % 3 == 2) {
          data = data.price_with_nds.toLocaleString('zh-ZH')
        } else return data
      } else {
        return data
      }
      return data
    }
  },
  computed: {
    nds() {
      var data1
      if (this.checkedNDS) {
        data1 = this.$store.state.requests.nds.nds
      } else {
        data1 = 0
      }
      return data1
    },
    gettruck() {
      let data = []
      let myList = []
      if (Boolean(this.selectedRow) && this.selectedRow.marshrut_description) {
        myList = new Set()
        data = this.selectedRow.marshrut_description
        // data.forEach(element => {
        data[data.length - 1].prices.forEach((price) => {
          const data = {
            truck_type: price.truck_type,
            is_ref: price.is_ref
          }
          myList.add(data)
        })
        myList = Array.from(myList)
        // })
      }
      return myList
    },
    selectedRow() {
      let data = {}
      data = this.getCurrContractDetails.find((x) => x.id == this.selectedItem)
      if (!data) data = {}
      return data
    },
    getCurrContractDetails() {
      let data = this.$store.state.requests.newMarshrut
      data.forEach((element) => {
        if (
          element.id ==
          Math.max.apply(
            null,
            data.map((x) => x.id)
          )
        )
          element.editable = true
        else element.editable = false
        // eslint-disable-next-line no-extra-boolean-cast
        if (Boolean(element.created_at)) {
          element.created_at = element.created_at.substr(0, 10)
        }
        // eslint-disable-next-line no-extra-boolean-cast
        // if (Boolean(element.marshrut_description)) {
        //   for (let i = 0; i < element.marshrut_description.length; i++) {
        //     for (
        //       let a = 0;
        //       a < element.marshrut_description[i].prices.length;
        //       a++
        //     ) {
        //       this.gettruck.forEach((truck, index) => {
        //         if (
        //           element.marshrut_description[i].prices[a].truck_type ==
        //           truck.id
        //         ) {
        //           element.marshrut_description[i].prices[a].position = index
        //         } else {
        //           element.marshrut_description[i].prices.push()
        //         }
        //       })
        //     }
        //   }
        //   element = element.marshrut_description
        // }
      })
      return data
    }
  },
  methods: {
    getExcel(val) {
      this.$store.commit('setIsLoading', true)
      axios
        .get('contracts/contract/appendix/exporttoexcel/' + val + '/', {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Ilova')
          document.body.appendChild(link)
          link.click()
          this.$store.commit('setIsLoading', false)
        })
    },
    deleteItem(val) {
      Swal.fire({
        title: 'Tasdiqlang',
        text: "Ma'lumot ochrilganidan so'ng qayta tiklay olmaysiz!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Xa, o'chirish",
        cancelButtonText: 'Bekor qilish'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire('Ochirildi!', '', 'success')
          this.$store.dispatch('deleteIlova', {
            id: val,
            page: this.$route.params.id
          })
        }
      })
    },
    truckModel(set, trucks) {
      let data
      if (Boolean(trucks) && Boolean(trucks.marshrut_description))
        trucks.marshrut_description.forEach((truck) => {
          data = truck.prices.find(
            (x) => x.truck_type == set.truck_type && x.is_ref == set.is_ref
          )
        })
      return data
    },
    showDetails(val) {
      this.selectedItem = val
      // this.dialog = true
    },
    hideDetails() {
      this.selectedItem = ''
      // this.dialog = false
    }
  }
}
</script>
<style>
@media (min-width: 992px) {
  .modal-md {
    max-width: auto !important;
  }
}
@media (min-width: 576px) {
  .modal-dialog,
  .modal-md {
    max-width: 100% !important;
    margin: 0 !important;
  }
}
</style>
<style scoped>
.test {
  display: block;
  height: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
}
.zebra thead tr:first-child th:first-child {
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
}
.zebra thead tr:first-child th {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
}
.zebra thead tr:first-child th:first-child {
  position: sticky;
  left: 0;
  z-index: 9999;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  background-color: #fff;
}
.zebra thead tr:nth-child(2) th {
  position: sticky;
  top: 75px;
  z-index: 999;
  background-color: #fff;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.zebra tbody tr:first-child td:first-child {
  position: sticky;
  left: 0;
  z-index: 999;
}
.zebra thead tr:first-child td {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
}
.zebra thead tr td {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
}
.zebra thead tr:nth-child(2) td {
  position: sticky;
  top: 96px;
  z-index: 999;
  background-color: #fff;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}
.zebra thead tr:first-child td:first-child {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 9999;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
}

.zebra tbody tr td {
  background-color: rgb(255, 255, 255);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.zebra thead {
  z-index: -9;
}
.zebra__wrapper {
  /* height: 450px; */
  overflow-x: scroll;
}
.zebra {
  border-spacing: 0;
  border-collapse: separate;
  /* height: 400px; */
  overflow-x: auto;
  height: 100%;
  white-space: nowrap;
}
td {
  text-align: center !important;
}
.table__cell {
  padding: 1.25rem !important;
}
.card-wrapper {
  padding-bottom: 20px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.card-wrapper__item {
  flex: 0 0 auto !important;
  margin-right: 10px;
}
.btn-card {
  min-width: 200px;
}
th,
td {
  padding: 0.25rem;
  text-align: left;
  /* border: 1px solid #000; */
}
tbody tr:nth-child(odd) {
  background: #eee;
}
</style>
