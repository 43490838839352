<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Shartnomalar malumotlari</h3>
            </div>
          </div>

          <div class="card-body">
            <div>
              <v-tabs v-model="tab" class="wizard-step">
                <v-tab
                  v-for="(item, index) in items"
                  :key="item.tab"
                  @click="tabNum(index)"
                >
                  {{ item.tab }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <div>
                        <h4 class="mb-10">Shartnoma malumotlari</h4>
                        <div class="d-flex">
                          <div class="col-3 pl-0">
                            <v-text-field
                              outlined
                              dense
                              label="Mijoz nomi"
                              disabled
                              :value="getCurrContractDetails.client_name"
                            ></v-text-field>
                          </div>

                          <div
                            class="col-3"
                            v-if="getCurrContractDetails.client_inn !== null"
                          >
                            <div>
                              <v-text-field
                                outlined
                                dense
                                label="Inn"
                                disabled
                                :value="getCurrContractDetails.client_inn"
                              ></v-text-field>
                            </div>
                          </div>

                          <div class="col-3" v-else>
                            <div>
                              <v-text-field
                                outlined
                                dense
                                label="Inn"
                                disabled
                                placeholder="Kiritilmagan"
                              ></v-text-field>
                            </div>
                          </div>

                          <div class="col-3">
                            <v-text-field
                              outlined
                              dense
                              label="Shartnoma turi"
                              disabled
                              :value="getCurrContractDetails.payment_type"
                            ></v-text-field>
                          </div>

                          <div class="col-3 pr-0">
                            <v-text-field
                              outlined
                              dense
                              label="To'lov turi"
                              disabled
                              :value="getCurrContractDetails.contract_type"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-12 px-0">
                          <v-text-field
                            outlined
                            dense
                            label="Shartnoma raqami"
                            disabled
                            :value="getCurrContractDetails.contract_number"
                          ></v-text-field>
                        </div>
                        <div class="d-flex">
                          <div class="col-3 pl-0">
                            <v-text-field
                              outlined
                              dense
                              label="Shartnoma bo'lgan sanasi"
                              disabled
                              :value="getCurrContractDetails.start_date"
                            ></v-text-field>
                          </div>

                          <div class="col-3">
                            <v-text-field
                              outlined
                              dense
                              label="Summa"
                              disabled
                              :value="getCurrContractDetails.amount"
                            ></v-text-field>
                          </div>

                          <div class="col-3 pr-0">
                            <v-text-field
                              outlined
                              dense
                              label="Foaligi"
                              disabled
                              :value="getCurrContractDetails.is_active_status"
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <div>
                        <div class="d-flex justify-content-between">
                          <h4
                            class="mb-10 text-dark"
                            style="text-transform: uppercase"
                          >
                            ilova
                          </h4>
                          <div>
                            <v-btn
                              color="primary"
                              :to="'/contractappenreg/' + this.$route.params.id"
                              dark
                            >
                              Maxsus ilova +
                            </v-btn>
                          </div>
                        </div>
                        <contractappen
                          v-bind:index="returnParamsId"
                        ></contractappen>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <div v-if="getZaDetaislById.length == 0">
                        <h4 class="mb-10 text-dark">Buyurtmalar topilmadi</h4>
                      </div>
                      <div v-else>
                        <h4 class="mb-10 text-dark">Buyurtmalar</h4>

                        <v-data-table
                          :headers="zayavkaList"
                          :items="getZaDetaislById"
                          class="elevation-1"
                        >
                          <template
                            v-slot:[`item.payment_type_name`]="{ item }"
                          >
                            <div
                              class="payment__name"
                              :class="item.payment_text_color"
                            >
                              <span
                                :class="[item.payment_color_label]"
                                class="label label-dot"
                              ></span>
                              {{ item.payment_type_name }}
                            </div>
                          </template>
                          <template v-slot:[`item.zayavka_status`]="{ item }">
                            <div
                              class="status__name"
                              :class="item.status_style"
                            >
                              {{ item.zayavka_status }}
                            </div>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <ZayavkaAction
                              :index="item"
                              @reload="reloadZayavs"
                            />
                          </template>
                        </v-data-table>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <div>
                        <div v-if="getCurrAdditionalContracts.length == 0">
                          <h4 class="mb-10 text-dark">
                            Kelishuv shartnomalar topilmadi
                            <span class="ml-3">
                              <i class="flaticon-warning-sign text-warning"></i>
                            </span>
                          </h4>
                        </div>
                        <div v-else>
                          <h4 class="mb-10 text-dark">Kelishuv shartnomalar</h4>
                          <template>
                            <v-data-table
                              :headers="headers"
                              :items="getCorrDetails"
                              :items-per-page="5"
                              class="elevation-1"
                            ></v-data-table>
                          </template>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <div>
                        <div v-if="getCombJobLength.length == 0">
                          <h4 class="mb-10 text-dark">
                            Bajarilgan ishlar topilmadi
                            <span class="ml-3">
                              <i class="flaticon-warning-sign text-warning"></i>
                            </span>
                          </h4>
                        </div>
                        <div v-else>
                          <div
                            class="
                              d-flex
                              align-items-center
                              justify-content-between
                              mb-5
                            "
                          >
                            <div>
                              <h4 class="text-dark">Bajarilgan ishlar</h4>
                            </div>
                            <!-- <div>
                              <v-btn
                                @click="sendData"
                                :disabled="selected.length == 0"
                                color="primary"
                                >Faktura +</v-btn
                              >
                            </div> -->
                          </div>
                          <div></div>
                          <template>
                            <v-row justify="space-between">
                              <v-col cols="6">
                                <v-row>
                                  <v-col cols="12" class="py-0" sm="6" md="4">
                                    <v-menu
                                      v-model="menu2"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="290px"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          v-model="fromDate"
                                          label="Boshlanishi"
                                          prepend-inner-icon="event"
                                          dense
                                          readonly
                                          solo
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="fromDate"
                                        @input="menu2 = false"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col cols="12" class="py-0" sm="6" md="4">
                                    <v-menu
                                      v-model="menu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="290px"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          v-model="toDate"
                                          label="Tugashi"
                                          prepend-inner-icon="event"
                                          readonly
                                          dense
                                          solo
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="toDate"
                                        @input="menu = false"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="2" class="text-right">
                                <v-btn
                                  :loading="isLoading"
                                  @click="sendData"
                                  :disabled="selected.length == 0"
                                  color="primary"
                                  >Faktura +</v-btn
                                >
                              </v-col>
                            </v-row>
                            <div class="v-data-table__wrapper">
                              <v-data-table
                                :loading="isLoading"
                                v-model="selected"
                                :headers="headersJob"
                                :items="getCompJob"
                                :items-per-page="5"
                                show-select
                                class="elevation-1 v-data-table__wrapper"
                              >
                                <template
                                  v-slot:[`item.payment_type_name`]="{ item }"
                                >
                                  <div :class="item.payment_text_color">
                                    <span
                                      v-bind:class="[item.payment_color_label]"
                                      class="label label-dot"
                                    ></span>
                                    {{ item.payment_type_name }}
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </template>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <div>
                        <!-- <div>
                          <h4 class="mb-10  text-dark">
                            Hisob fakturalar topilmadi
                            <span class="ml-3">
                              <i class="flaticon-warning-sign text-warning"></i>
                            </span>
                          </h4>
                        </div> -->
                        <div>
                          <h4 class="mb-10 text-dark">Hisob fakturalar</h4>

                          <v-data-table
                            :loading="isLoading"
                            :headers="headers222"
                            :items="factureBycontract"
                            hide-default-footer
                          >
                            <template v-slot:[`item.action`]="{ item }">
                              <FacturaAction :index="item" />
                            </template>
                          </v-data-table>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <Payments />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contractappen from './contractappen'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ZayavkaAction from '@/view/content/dropdown/ZayavkaAction.vue'
import FacturaAction from '@/view/content/dropdown/FacturaAction.vue'
import Payments from './components/Payments'

// import KTUtil from "@/assets/js/components/util";
// import KTWizard from "@/assets/js/components/wizard";
// import Factura from "./components/factura";
export default {
  name: 'ContractDetails',
  data() {
    return {
      range: {},
      toDate: new Date().toISOString().substr(0, 10),
      fromDate: '',
      menu2: false,
      menu: false,
      name: '',
      selected: [],
      tab: null,
      items: [
        {
          tab: 'Umumiy'
        },
        {
          tab: 'Ilova'
        },
        { tab: 'Buyurtma' },
        { tab: 'Dop soglashenie' },

        { tab: 'Bajarilgan ish' },
        { tab: 'Hisob Faktura' },
        { tab: 'TULOVLAR' }
      ],
      msg: 'Shartnomalar malumotari',
      selectedID: '',
      fields: [
        {
          label: 'Mijoz nomi',
          key: 'zayavka_owner'
        },
        {
          label: 'INN',
          key: 'client_inn'
        },
        {
          label: 'Turi',
          key: 'contract_type'
        },
        {
          label: 'Shartnoma raqami',
          key: 'contract_number'
        },
        {
          label: 'Yaratilgan sana',
          key: 'start_date'
        },
        {
          label: 'Tugash sanasi',
          key: 'end_date'
        }
      ],
      headers222: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Mijoz',
          value: 'client_name'
        },
        {
          text: 'Raqami',
          value: 'reg_number'
        },
        {
          text: 'Shartnoma',
          value: 'contract'
        },
        {
          text: 'Narxi',
          value: 'get_factura_amount.price'
        },
        {
          text: 'Nds',
          value: 'get_factura_amount.nds'
        },
        {
          text: 'narxi(nds)',
          value: 'get_factura_amount.price_with_nds'
        },
        {
          text: 'Soni',
          value: 'get_factura_amount.count'
        },
        {
          text: 'Sana',
          value: 'oper_date'
        },
        { text: '', value: 'action', sortable: false }
      ],
      headersJob: [
        { text: 'Id', value: 'id' },
        { text: "Yo'nalishi", value: 'direction_truck.truck_type.brand.name' },
        { text: 'Postavshik', value: 'deliver' },
        { text: 'Boshlanish', value: 'start_date' },
        { text: 'Tugash', value: 'end_date' },
        { text: 'Summa', value: 'price' },
        { text: 'Nds Summa', value: 'nds_summa' },
        { text: 'Summa(Nds)', value: 'price_with_nds' }
      ]
    }
  },
  created() {
    this.$store.dispatch('getClientContract', this.$route.params.id)
    this.$store.dispatch('getAllAdditionalContracts', this.$route.params.id)
    this.$store.dispatch('getZaById', this.$route.params.id)
    this.$store.dispatch('getAllTruckTypes')
    this.$store.dispatch('getNameOfContract', this.$route.params.id)
    this.$store.dispatch('getCurrCompJob', this.$route.params.id)
    this.$store.dispatch('factureBycontract', this.$route.params.id)
    this.$store.dispatch('contractPayments', this.$route.params.id)
    this.$store.dispatch('getNds')
  },
  computed: {
    getCurrContractDetails() {
      const data = this.$store.state.requests.allContracts
      if (data.amount !== undefined && data.amount !== null) {
        data.amount = data.amount.toLocaleString('es-US')
      }
      return this.$store.state.requests.allContracts
    },
    getCurrAdditionalContracts() {
      return this.$store.state.requests.allclientcontracts
    },
    factureBycontract() {
      return this.$store.state.requests.allFacturasByContract
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },

    getZaDetaislById() {
      return this.$store.state.requests.setZayavById
    },
    gettruck() {
      return this.$store.state.requests.allTruckTypes
    },
    returnSelectedId() {
      return this.selectedID
    },
    returnParamsId() {
      return this.$route.params.id
    },
    getNameCon() {
      return this.$store.state.requests.nameOfContracts
    },
    getCorrDetails() {
      this.$store.state.requests.allclientcontracts.forEach((element) => {
        if (element.contract_type == 'MU') {
          element.contract_type = 'Muddatli'
        } else if (element.contract_type == 'SU') {
          element.contract_type = 'Summa'
        } else {
          element.contract_type = 'Muddatsiz'
        }
        if (element.is_active == true) {
          element.is_active = 'Faol'
        } else {
          element.is_active = 'Nofaol'
        }
      })
      return this.$store.state.requests.allclientcontracts
    },
    getCompJob() {
      return this.$store.state.requests.currCompJob
      // data.forEach(element => {
      //   element.direction_truck_name = ''
      //   if (
      //     element.price !== undefined &&
      //     element.price_with_nds !== undefined &&
      //     element.nds_summa !== undefined
      //   ) {
      //     element.price = element.price.toLocaleString('es-US')
      //     element.post_kg = element.post_kg.toLocaleString('es-US')
      //     element.price_with_nds = element.price_with_nds.toLocaleString(
      //       'es-US'
      //     )
      //     element.nds_summa = element.nds_summa.toLocaleString('es-US')
      //   }
      //   // eslint-disable-next-line no-extra-boolean-cast
      //   if (Boolean(element.start_date)) {
      //     element.start_date = element.start_date.toString().substr(0, 10)
      //   }
      //   // eslint-disable-next-line no-extra-boolean-cast
      //   if (Boolean(element.end_date)) {
      //     element.end_date = element.end_date.toString().substr(0, 10)
      //   }
      //   // eslint-disable-next-line no-extra-boolean-cast
      //   if (Boolean(element.direction_truck)) {
      //     element.direction_truck.direction.citydestination.forEach(x => {
      //       if (element.direction_truck_name !== '') {
      //         element.direction_truck_name =
      //           element.direction_truck_name + ' - ' + x.name
      //       } else element.direction_truck_name = x.name
      //     })
      //   }
      // })
      // if (this.fromDate !== '') {
      //   data = data.filter(
      //     x => x.created_at >= this.fromDate && x.delivered_date <= this.toDate
      //   )
      // }
      // return data
    },
    getCombJobLength() {
      var data = this.$store.state.requests.currCompJob
      return data
    },
    headers() {
      return [
        { text: 'Id', value: 'id' },
        { text: 'Mijoz nomi', value: 'client_name' },
        { text: 'Inn', value: 'client_inn' },
        { text: 'Turi', value: 'contract_type' },
        { text: 'Reg raqami', value: 'contract_number' },
        { text: 'Boshlanish', value: 'start_date' },
        { text: 'Tugash', value: 'end_date' },
        { text: 'Summa', value: 'amount' },
        { text: 'Faol', value: 'is_active' }
      ]
    },
    zayavkaList() {
      return [
        { text: 'ID', value: 'id' },
        { text: 'Buyurtma ownrer', value: 'zayavka_owner' },
        { text: 'Postavshik', value: 'deliver' },
        { text: 'Narxi', value: 'price' },
        { text: 'Kg', value: 'post_kg' },
        { text: 'm3', value: 'post_m3' },
        // { text: "Toʻlov turi", value: "payment_type_name" },
        { text: 'Status', value: 'zayavka_status' },
        { text: '', value: 'actions', sortable: false }
      ]
    }
  },
  methods: {
    reloadZayavs() {
      // console.log('bir nima')
      this.$store.dispatch('getZaById', this.$route.params.id)
    },
    tabNum(value) {
      if (value == '5') {
        this.$store.dispatch('factureBycontract', this.$route.params.id)
      }
    },
    async sendData() {
      const data = {
        contract: parseInt(this.$route.params.id),
        zayavkas: this.selected.filter((x) => x.id == x.id).map((x) => x.id)
      }
      console.log(data)
      await this.$store.dispatch('contractFactures', data)
      await this.$store.dispatch('factureBycontract', this.$route.params.id)
    },
    selectedTruck(payload) {
      this.selectedID = payload
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Shartnoma malumotlari' }])
  },

  components: {
    contractappen,
    Payments,
    FacturaAction,
    ZayavkaAction
  }
}
</script>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
</style>
<style scoped>
.overlay_style {
  z-index: 999;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  color: #fff;
  background-color: #333;
}
.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: inherit;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 0.25rem;
  text-align: left;
  border: 1px solid #ccc;
}
tbody tr:nth-child(odd) {
  background: #eee;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.wizard-step {
  padding: 0;
  padding-bottom: 30px;
}
</style>
