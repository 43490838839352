<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover" style="width: 250px">
      <b-dropdown-text tag="div" class="navi-item">
        <!-- <div>
          <router-link :to="'/zayavkadetail/' + index.id">
            <div class="w-100 navi-link">
              <span class="navi-icon">
                <i class="flaticon2-file-1 text-success"></i>
              </span>
              <span class="navi-text">{{ $t('ACTION.DETAILS') }}</span>
            </div>
          </router-link>
        </div> -->
        <div>
          <router-link :to="'/newzayavkadetail/' + index.id">
            <div class="w-100 navi-link">
              <span class="navi-icon">
                <i class="flaticon2-file-1 text-success"></i>
              </span>
              <span class="navi-text">{{ $t('ACTION.DETAILS') }}</span>
            </div>
          </router-link>
        </div>
        <router-link :to="'/zayavkaedit/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
        </router-link>

        <template v-if="index.zayavka_status !== 'Yetkazilgan'">
          <div class="card__action">
            <v-dialog v-model="dialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <div class="navi-link w-100" v-bind="attrs" v-on="on">
                  <span class="navi-icon">
                    <i class="flaticon2-file-1 text-primary"></i>
                  </span>
                  <span class="navi-text">{{
                    $t('ACTION.CHANGE_STATUS')
                  }}</span>
                </div>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Statusni o'zgartirish</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-select
                          v-model="select"
                          :hint="`${[
                            selected.state || '',
                            selected.abbr || ''
                          ].join(',')}`"
                          :items="items"
                          item-text="state"
                          item-value="abbr"
                          label="Status"
                          return-object
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false"
                    >Yopish</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    :disabled="index.zayavka_status == 'DEL'"
                    text
                    @click="update"
                    >Saqlash</v-btn
                  >
                  <v-dialog
                    v-model="confirmModal"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                  >
                    <v-card>
                      <v-toolbar dark color="primary">
                        <v-btn icon dark @click="confirmModal = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Rasimni yuklash</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <v-btn dark text @click="save">Saqlash</v-btn>
                        </v-toolbar-items>
                      </v-toolbar>
                      <v-container fluid>
                        <v-row justify="center">
                          <v-col cols="12" md="6">
                            <div class="card card-custom container">
                              <div class="card-header px-0">
                                <div class="card-title">
                                  <h3 class="card-label">Izoh</h3>
                                </div>
                              </div>
                              <!--begin::Form-->
                              <v-textarea
                                v-model="comments"
                                solo
                                name="input-7-4"
                                placeholder="Izoh qoldiring"
                              ></v-textarea>
                              <b-form-file
                                v-model="files"
                                :state="Boolean(files)"
                                multiple
                                accept=".jpg, .jpeg"
                                placeholder="Rasm joylash"
                                drop-placeholder="Drop file here..."
                              ></b-form-file>
                              <!--end::Form-->
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-dialog>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>

        <template v-else-if="index.zayavka_status == 'Yetkazilgan'">
          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <div class="navi-link w-100" v-bind="attrs" v-on="on">
                <span class="navi-icon">
                  <i class="flaticon2-file-1 text-primary"></i>
                </span>
                <span class="navi-text">Masofasini o'zgartirish</span>
              </div>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Buyurtma masofasini o'zgartirish</span>
              </v-card-title>
              <v-card-text>
                <!-- <v-container> -->
                <v-row>
                  <v-col>
                    <div class="h4 mt-5 font-weight-bold">
                      Hozirgi masofa: {{ index.zayavka_distance || 0 }} km
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      class="m-0 p-0"
                      @keypress="isNumberKey"
                      v-model="newDistance"
                      label="Buyurtma masofasi"
                      placeholder="Yangi buyurtma masofasini kiriting"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- </v-container> -->
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false"
                  >Yopish</v-btn
                >
                <v-btn
                  color="primary"
                  elevation="0"
                  @click="updateZayavkaDistance"
                  >Saqlash</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <!-- <router-link :to="'/zayavkaedit/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-delete-1 text-danger"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.DELETE') }}</span>
        </router-link> -->
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<script>
// import VueUploadMultipleImage from "vue-upload-multiple-image";
import axios from 'axios'
import Swal from 'sweetalert2'
import { isNumberKey } from '@/utils'
import { mapActions } from 'vuex'

export default {
  components: {},
  name: 'ZayavkaAction',
  data: () => ({
    files: [],
    comments: '',
    confirmModal: false,
    dialog: false,
    select: { state: '', abbr: '' },
    newDistance: null
  }),
  props: {
    btnClass: String,
    index: Object,
    id: Number,
    page: Number
  },
  computed: {
    selected() {
      const data = {
        state: this.index.zayavka_status_name,
        abbr: this.index.zayavka_status
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.select = data
      return data
    },
    items() {
      let data
      if (this.selected.abbr == 'Buyurtmachida') {
        data = [
          { state: 'Yetqazib berilgan', abbr: 'DEL' },
          { state: "Yo'lda", abbr: 'OTW' }
        ]
      } else if (this.selected.abbr == "Yo'lda") {
        data = [{ state: 'Yetqazib berilgan', abbr: 'DEL' }]
      } else if (this.selected.abbr == 'Yetqazib berilgan') {
        data = []
      }
      return data
    }
  },
  methods: {
    isNumberKey,

    ...mapActions(['setZayavkaItem']),

    async updateZayavkaDistance() {
      if (!this.newDistance) {
        Swal.fire({
          // title: 'Formada xatolik bor',
          title: 'Yangi masofani kiriting',
          icon: 'error'
        })
        return
      }
      try {
        await this.setZayavkaItem({
          zayavkaItemId: this.index.id,
          data: {
            zayavka_distance: this.newDistance
          }
        })
        Swal.fire({
          // title: 'Formada xatolik bor',
          title: "Yangi masofa muvaffaqiyatli o'zgardi",
          icon: 'success'
        })

        this.newDistance = null

        this.$emit('reload')
      } catch (err) {
        //
        this.dialog = false
        this.newDistance = null
        Swal.fire({
          // title: 'Formada xatolik bor',
          title: "Yangi masofa o'zgartirishda xato",
          icon: 'error'
        })
      }

      this.dialog = false
    },

    asd() {
      // formData.append("zayavka", this.index.id);
      this.files.forEach((element) => {
        let formData = new FormData()
        formData.append('foto_report', element)
        formData.append('zayavka', this.index.id)
        axios.post('zayavkas/photoreport/', formData).then(() => {})
      })
      // console.log(formData)
    },
    save() {
      if (this.files.length !== 0 && this.comments !== '') {
        this.files.forEach((element) => {
          let formData = new FormData()
          formData.append('foto_report', element)
          formData.append('zayavka', this.index.id)
          axios.post('zayavkas/photoreport/', formData).then(() => {})
        })
        const data = {
          zayavka_status: 'DEL',
          driver_delivered_comment: this.comments,
          delivered_date: new Date().toISOString().substr(0, 16)
        }
        console.log('0000', data)
        // this.$store.dispatch('confirmZayavkaStatus', {
        //   id: this.index.id,
        //   data1: data,
        //   page: this.$route.params.id
        // })
        this.comments = ''
        this.files = ''
        this.confirmModal = false
        this.dialog = false
      } else {
        Swal.fire({
          title: '',
          text: 'Formada xatolik bor',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      }
    },
    update() {
      // console.log(this.select);
      const data = {
        zayavka_status: this.select.abbr
      }
      if (this.select.abbr !== 'DEL') {
        this.$store.dispatch('updateZayavkaStatus', {
          id: this.index.id,
          page: this.$route.params.id,
          data: data
        })
        this.dialog = false
      } else {
        this.confirmModal = true
      }
    }
  },
  mounted() {}
}
</script>

<style scoped>
.image-container {
  width: 100%;
}
#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
