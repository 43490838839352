<template>
  <div>
    <div v-if="contractPayments.length == 0">
      <h4 class="mb-10 font-weight-bold text-danger">To'lovlar topilmadi</h4>
    </div>
    <div v-else>
      <h4 class="mb-10 font-weight-bold text-dark">To'lovlar</h4>
      <template>
        <v-data-table
          :headers="headers"
          :items="contractPayments"
          class="elevation-1"
          :loading="isLoading"
          :page.sync="page"
          :items-per-page="5"
          hide-default-footer
        >
          <template v-slot:[`item.transfer_name`]="{ item }">
            <div :class="item.transfer_name_class">
              <span
                :class="item.transfer_name_class_label"
                class="label-primary label label-dot"
              ></span>
              {{ item.transfer_name }}
            </div>
          </template>
        </v-data-table>
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="Math.ceil(contractPayments.length / 5)"
          ></v-pagination>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    page: 1,

    headers: [
      {
        text: 'ID',
        value: 'id'
      },
      {
        text: 'Turi',
        value: 'transfer_name'
      },
      {
        text: 'Summa',
        value: 'amount'
      },
      {
        text: 'Sana',
        value: 'oper_date'
      },
      {
        text: 'Tranzaksiya',
        value: 'transfer'
      }
    ]
  }),
  beforeCreate() {
    this.$store.dispatch('contractPayments', this.$route.params.id)
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getCount() {
      return this.$store.state.requests.operationsBycontract
    },
    contractPayments() {
      const data = this.$store.state.requests.operationsBycontract
      if (data !== undefined) {
        data.forEach((element) => {
          if (element.amount !== null) {
            element.amount = element.amount.toLocaleString('es-US')
            if (element.transfer_name == 'Kirim') {
              element.transfer_name_class = 'text-success'
              element.transfer_name_class_label = 'label-success'
            } else {
              element.transfer_name_class = 'text-danger'
              element.transfer_name_class_label = 'label-danger'
            }
          }
        })
      }
      return data
    }
  },
  methods: {
    getPostData() {}
  }
}
</script>

<style scoped>
.v-data-table {
  min-height: 200px !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: unset !important;
  overflow-y: unset !important;
  min-height: 250px;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: inherit;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 0.25rem;
  text-align: left;
  border: 1px solid #ccc;
}
tbody tr:nth-child(odd) {
  background: #eee;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.wizard-step {
  padding: 0;
  padding-bottom: 30px;
}
</style>
